.socialmediaBackground{
    max-width:100%;
    padding: 1%;
    background-color: white;
}

.socialmediaHeader{
    font-size: 40px;
    font-weight: bold;
    text-align: center;
}

.socialmediaButton{
    width: 90px;
    height: 90px;
    margin: 1%;
}

@media (max-width: 550px) 
{
  .desktopSocialMediaButton
    {
        display: none !important;
    }
}
