.SignUpForm{
    position: absolute;
    background-color: white;
    padding: 1%;
    width: 15%;
    z-index: 100;
    top: 8%;
    right: 1%;
    min-width: 200px;
    font-size: 20px !important;
}

.signUpFormField{
    margin-bottom: 5%;
}

.signUpSubmit{
    margin-top: 5%;
}

.signUpSubmitButton{
    margin-right: 5%;
    font-size: 16px !important;
}

.signUpSwitchLink{
    font-size: 16px !important;
}

::-webkit-input-placeholder {
    font-size: 16px!important;
 }
 
 :-moz-placeholder { /* Firefox 18- */
       font-size: 16px!important;
 }
 ::-moz-placeholder {  /* Firefox 19+ */
       font-size: 16px!important;
 }