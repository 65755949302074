  .introductionBackground{
    background-image: url("../../assets/IntroductionPageBG.svg");
      min-height: 535px;
      background-position: middle;
      background-repeat: no-repeat;
      background-size: cover;
    padding-bottom: 5%;
  }

.introductionInformation {
  padding-top: 80px;
}

.introductionHeader {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
}

.introductionFooter {
  margin-top: -1%;
  font-style: normal;
  font-weight: normal;
  font-size: 55px;
  color: #BAB8B8;
}

.introductionText {
  margin-top: 2%;
  max-width: 60%;
  font-size: 30px;
  line-height: 35px;
  margin-left: 0.5vw;
  margin-bottom: 3%;
  color: #E7E7E7;
}

@media (max-width: 800px) 
{
  .introductionText
    {
        display: none;
    }
}

.introductionSignInButton{
  margin-right: 2%;
  font-size: 22px !important;
}

.introductionPlayButton{
  font-size: 22px !important;
}