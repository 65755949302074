.leaderboardBackground {
    background-image: url("../../assets/LeaderboardPageBG.svg");
    min-height: 130vh;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
}

.leaderboardHeader{
    text-align: center;
    margin-bottom: 60px;
    padding-top: 50px;
}

.leaderboardHeaderText{
    color: white;
    font-size: 80px;
    font-weight: bold;
}

.leaderboardFooterText{
    color: #BAB8B8;
    font-size: 40px;
}

.LeaderboardTable{
    width: 1000px;
    font-size: 20px;
    margin-bottom: 80px;
}