.informationBackground{
    padding-bottom: 5%;
}

.informationRow{
    margin-top:5%!important;
}

.InformationHeader{
    font-size: 60px;
    font-weight: bold;
}

.InformationFooter{
    margin-top: -10px;
    margin-bottom: 30px;
    color: #BAB8B8;
    font-size: 50px;
}

.InformationText{
    margin-top: -10px;
    padding-left: 10px;
    font-size: 20px;
    border-left: solid 2px #686868;
}