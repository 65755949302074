/* Scroll bar */
::-webkit-scrollbar-track {
    background: #252525;
}

.gameBackground {
    background-image: url("../../assets/GamePageBG.svg");
    min-height: 120vh;
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
}

.gameHeader {
    padding-top: 80px;
    color: white;
    font-weight: bold;
    font-size: 50px;
}

.Console {
    width: 100%;
    min-height: 150px;
    height: 67vh;
    color: #ffffff;
    background-color: #252525;
    border-color: #585858;
    border-width: 2px;
    font-size: 20px;
    outline: none;
}

.gameInput {
    width: 100%;
    color: #ffffff;
    background-color: #252525;
    border-color: #585858;
    border-width: 2px;
    font-size: 20px;
    resize: none;
    outline: none;
}

.sendCommandIcon {
    align-content: flex-end;
    position: relative;
    top: -39px;
    right: 7px;
    color: #585858;
}

.characterStats {
    color: #ffffff;
    background-color: #252525;
    border-color: #585858;
    border-width: 2px;
    border-style: solid;
    font-size: 20px;
}

.statsoption {
    margin-right: 15px;
}

.statsoption:hover {
    cursor: pointer;
    color: #ffffff !important;
}

.selectedStatOption {
    color: #ffffff !important;
}

.statsInformationWindow {
    font-size: 40px;
}

.Inventory {
    max-height: 180px;
    font-size: 20px;
}

.EquipedItem{
    color: rgb(114, 217, 224);
}